<template>
  <v-alert
    text
    color="primary"
  >
    <h3 class="text-h5">
      {{ $t('booking.reservations.title', locale) }}
    </h3>
    <div>
    </div>

    <v-divider
      class="my-4 info"
      style="opacity: 0.22"
    />
    <v-row 
      v-if="!loading && (!activities || activities.length === 0)"
      align="center" 
      style="margin: 20px 0 -10px 0;"
    >
      <v-alert
        text
        outlined
        color="deep-orange"
        icon="mdi-calendar-question"
        width="100%"
      >
       {{ $t('booking.reservations.messages.noEventsForSelectedDate', locale) }}
      </v-alert>
    </v-row>
    <v-row v-else align="center" style="margin: 20px 0 10px 0;">
      <v-autocomplete
        :label="$t('booking.activities.title', locale)"
        v-model="selectedActivity"
        :loading="loading"
        :items="activities"
        dense
        hide-no-data
        hide-details
        color="primary"
        item-text="name"
        item-value="id"
        @change="handleChangeActivity"
        outlined
      />
      <br/>
      <v-checkbox 
        v-model="excludeHide"
        :label="$t('booking.excludeHide', locale)"
        @change="handleGetEvents"
        style="margin-left: 10px;"
      />
    </v-row>
    <confirm-hide-event
      v-if="selectedEventToHide"
      :show="selectedEventToHide !== null"
      :locale="locale"
      :title="$t(`booking.messages.${selectedEventToHide.Hide ? 'show' : 'hide'}Confirm`, locale)"
      :onOK="handleHideEvent"
      :onKO="() => selectedEventToHide = null"
    />
    <v-row
      align="center"
      no-gutters
      v-for="event of eventsFiltered"
      :key="event.ID"
    >
      <v-col 
        style="padding-top: 5px;padding-right:5px;"
        cols="2"
      >
        <v-btn
          block
          outlined
          @click="() => selectedEventToHide = event"
        >
          <v-icon
            :color="event.Hide ? 'red' : 'green'"
          >
            mdi-eye
          </v-icon>
        </v-btn>
      </v-col>
      <v-col 
        style="padding-top: 5px;"
        cols="10"
      >
        <!--v-btn
          color="primary"
          outlined
          @click="handleAddItem(event)"
          block
        >
          {{ convertTime(event.Time) }} {{ event.name }}
        </v-btn-->
        <v-btn
          color="primary"
          outlined
          @click="handleAddItem(event)"
          block
          :disabled="event.Free <= 0 || event.Hide === true || event.Hide === 1"
        >
          {{ convertTime(event.Time) }} → {{ $t('booking.reservations.addReservation', locale) }} ({{ event.Free > 0 ? `${event.Free}` : $t('booking.complete', locale) }}) 
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import ConfirmHideEvent from './ConfirmHideEvent'
export default {
  components: {
    ConfirmHideEvent,
  },
  props: {
    selectedDate: {
      type: String,
      default: null,
    },
    onAdd: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    events: [],
    eventsFiltered: [],
    activities: [],
    selectedActivity: null,

    excludeHide: true,
    selectedEventToHide: null,
  }),
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace', 'bookingRefreshReservationList']),
  },
  watch: {
    selectedDate () {
      this.handleGetEvents()
    },
    bookingWorkspace () {
      this.handleGetEvents()
    },
    bookingRefreshReservationList () {
      this.handleGetEvents()
    },
  },
  mounted () {
    this.handleGetEvents()
  },
  methods: {
    convertTime (v) {
      return utils.convertTime(v).split(' ').join('')
    },
    handleGetEvents () {
      this.eventsFiltered = []
      this.loading = true
      const filter = `?min=${this.selectedDate}&max=${this.selectedDate}&${this.excludeHide ? `excludeHide=true&` : `includeHideEvents=true&` }`
      api.getAllWithoutLimit('booking', `v1/public/workspaces/${this.bookingWorkspace}/events${filter}`)
        .then(response => {
          if (response) {
            //TODO check if correct
            response = response.filter(x => x.AllowReservations)
            if (this.selectedDate === utils.checkDate(new Date())) 
              this.events = response.filter(x => x.AllowBook && x.Time >= ((new Date().getHours() * 60) + new Date().getMinutes()))
                                    .map(item => { 
                                      item.name = utils.getTranslation(item.ActivityName, this.locale)
                                      return item
                                    })
                                    .sort((a, b) => a.ActivityID-b.ActivityID)
            else if (new Date() > new Date(this.selectedDate)) this.events = []
            else this.events = response.sort((a, b) => a.ActivityID-b.ActivityID)
                                    .map(item => { 
                                      item.name = utils.getTranslation(item.ActivityName, this.locale)
                                      return item
                                    })
                                    .sort((a, b) => a.ActivityID-b.ActivityID)

            //TODO tmp
this.events = response.sort((a, b) => a.ActivityID-b.ActivityID)
                                    .map(item => { 
                                      item.name = utils.getTranslation(item.ActivityName, this.locale)
                                      return item
                                    })
                                    .sort((a, b) => a.ActivityID-b.ActivityID)


            //TODO tmp

            // this.events = response.sort((a, b) => a.ActivityID-b.ActivityID)
            this.activities = [...new Map(this.events.map(item => [item['ActivityID'], item])).values()].map(item => { return { id: item.ActivityID, name: item.name } })
            if (this.activities.length > 0) {
              this.selectedActivity = this.activities[0].id
              this.handleChangeActivity()
            }
          } else {
            this.events = []
            this.activities = []
          }
          this.loading = false
        })
    },
    handleChangeActivity () {
      this.eventsFiltered = this.events.filter(x => x.ActivityID === this.selectedActivity)
    },
    handleAddItem (v) {
      this.onAdd(v)
    },
    handleHideEvent () {
      const event = this.events.filter(x => x.ID === this.selectedEventToHide.ID).shift()
      api.updateItem ('booking', `v1/private/events/`, event.ID.concat('/hide'), {Hide: !event.Hide})
        .then(response => {
          if (response) {
            this.selectedEventToHide = null
            this.handleGetEvents()
          }
        })
        .catch (() => {
          alert( 'Error' )
        })
    },
  },
}
</script>

